<template>
  <v-card>
    <v-card-text>
      <!-- editor -->
      <editor :initial="dataItem" :docCreate="docCreate" @data="save"></editor>
      <!-- end -->
      <snackbar ref="snackbar"></snackbar>
    </v-card-text>
    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Editor from "../_components/editor.vue";
export default {
  components: {
    Editor,
  },
  data: () => ({
    dataItem: {},
    docCreate: false,
    dialog: true,
  }),
  watch: {
    "$route.params.id": {
      handler: "getDocument",
      immediate: true,
    },
  },
  methods: {
    getDocument(id) {
      const self = this;
      this.$store
        .dispatch(
          "get",
          `/dispatch/documents/${id}?ObjType=${this.$route.params.ObjType}`
        )
        .then((res) => {
          self.dataItem = res.ResponseData;
          self.dialog = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    save(data) {
      const url = `/dispatch/documents/${data.id}?ObjType=${data.ObjType}`;
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("put", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            this.$store.commit("loader", false);
            self.loader = false;
            self.$refs.snackbar.show(res.ResultDesc, "green");
            this.getDocument(data.id);
          } else {
            self.loader = false;
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          self.loader = false;
          this.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },
  },
};
</script>