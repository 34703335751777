<template>
  <v-container fluid>
    <v-toolbar color="primary" flat dense>
      <v-toolbar-title class="white--text">Document</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="white" small text @click="goBack">
        <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
      </v-btn>
    </v-toolbar>
    <v-form class="mt-2">
      <v-row dense>
        <v-col cols="3">
          <v-text-field
            outlined
            dense
            label="Document Number"
            v-model="record.DocNum"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            outlined
            dense
            label="DocTotal"
            v-model="record.DocTotal"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            outlined
            dense
            label="ExtRef"
            v-model="record.ExtRef"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            outlined
            dense
            label="ExtDocTotal"
            v-model="record.ExtDocTotal"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3">
          <v-text-field
            outlined
            dense
            label="CardName"
            v-model="record.CardName"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            outlined
            dense
            label="CardCode"
            v-model="record.CardCode"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            outlined
            dense
            label="ExtRefDocNum"
            v-model="record.ExtRefDocNum"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            outlined
            dense
            label="DocDate"
            v-model="record.DocDate"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3">
          <v-autocomplete
            outlined
            dense
            v-model="record.RlpCode"
            :items="drivers"
            item-text="RlpName"
            item-value="RlpCode"
            label="Driver"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            outlined
            dense
            v-model="record.vehicle_id"
            :items="vehicles"
            label="Vehicle"
            item-text="RegistrationNO"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            outlined
            disabled
            dense
            :items="salesEmployees"
            item-text="SlpName"
            item-value="SlpCode"
            v-model="record.SlpCode"
            label="sales Employees"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            :items="Employees"
            item-text="full_name"
            item-value="empID"
            outlined
            disabled
            dense
            v-model="record.OwnerCode"
            label="Doc Owner"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-card>
        <v-toolbar color="primary" flat dense>
          <v-toolbar-title class="white--text">Document Lines</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="record.document_lines"
            :search="search"
            dense
          >
            <!-- To Release -->
            <template v-slot:item.Quantity="props">
              <v-text-field
                outlined
                dense
                v-model="props.item.Quantity"
                single-line
              ></v-text-field>
            </template>
            <!-- end -->
            <!--Line status-->
            <template v-slot:item.LineStatus="props">
              <span
                :style="{
                  color: props.item.LineStatus === 'O' ? 'green' : 'red',
                }"
              >
                {{ props.item.LineStatus === "O" ? "Open" : "Closed" }}
              </span>
            </template>
            <!--End Line status-->
          </v-data-table>
          <v-btn color="primary" @click="sendData" :loading="loader">
            <v-icon left>mdi-content-save</v-icon>Save
          </v-btn>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>
<script>
export default {
  props: {
    initial: {
      type: Object,
    },
  },
  data: () => ({
    salesEmployees: [],
    record: {},
    search: "",
    loader: false,
    vehicles: [],
    drivers: [],
    headers: [
      { text: "Id", value: "id" },
      { text: "BaseEntry", value: "BaseEntry" },
      { text: "Line No.", value: "LineNum" },
      { text: "Dscription", value: "Dscription" },
      { text: "Item Code", value: "ItemCode" },
      { text: "LineStatus", value: "LineStatus" },
      { text: "Price", value: "Price" },
      { text: "VatSum", value: "VatSum" },
      { text: "LineTotal", value: "LineTotal" },
      { text: "unitMsr", value: "unitMsr" },
      { text: "OpenQty", value: "OpenQty" },
      { text: "Quantity", value: "Quantity" },
    ],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },

  methods: {
    init(val) {
      this.record = { ...val };
    },
    sendData() {
      this.$emit("data", this.record);
    },
    getvehicles() {
      const self = this;
      this.$store
        .dispatch("get", `/vehicles`)
        .then((res) => {
          self.vehicles = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getdrivers() {
      const self = this;
      this.$store
        .dispatch("get", `/drivers`)
        .then((res) => {
          self.drivers = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getSalesEmployees() {
      const self = this;
      this.$store
        .dispatch("get", `/employee`)
        .then((res) => {
          self.salesEmployees = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getEmployees() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/employee-master-data`)
        .then((res) => {
          self.Employees = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  created() {
    this.getdrivers();
    this.getvehicles();
    this.getEmployees();
    this.getSalesEmployees();
  },
};
</script>